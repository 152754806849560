main {
  min-height: calc(100vh - 100px);
}
.featurette-divider {
  margin-top: 80px;
  margin-bottom: 80px;
}

footer {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eee;
}
